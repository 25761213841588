import axios from "axios";

export default {
    setStateValue({commit}, payload) {
        commit('setStateValue', payload);
    },
    async getSmsForSign({ commit, dispatch }, payload)
    {
        try {
            const response = await axios.post('/api/typical-contract/get-sms-for-sign', payload);

            if (!response.data.status) {
                return response.data;
            }

            /*commit('addDealPreliminaryCalculationToDealPreliminaryCalculationList', {
                id: response.data.deal_calculation.id,
                deal_id: response.data.deal_calculation.deal_id,
                preliminary_calculation_id: response.data.deal_calculation.preliminary_calculation_id,
                title: payload.title,
            });*/

            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 422) {
                return {
                    status: false,
                    errors: error.response.data.errors,
                };
            }

            return {
                status: false,
                message: error.message || 'Something went wrong',
            };
        }
    },
    async signDocument({ commit, dispatch }, payload)
    {
        try {
            const response = await axios.post('/api/typical-contract/sign-document', payload);

            if (!response.data.status) {
                return response.data;
            }

            /*commit('addDealPreliminaryCalculationToDealPreliminaryCalculationList', {
                id: response.data.deal_calculation.id,
                deal_id: response.data.deal_calculation.deal_id,
                preliminary_calculation_id: response.data.deal_calculation.preliminary_calculation_id,
                title: payload.title,
            });*/

            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 422) {
                return {
                    status: false,
                    errors: error.response.data.errors,
                };
            }

            return {
                status: false,
                message: error.message || 'Something went wrong',
            };
        }
    },
}

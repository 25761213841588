import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    name: null,
    surname: null,
    patronymic: null,
    email: null,
    phone: null,
    passport_serial: null,
    passport_number: null,
    passport_issue_date: null,
    passport_who_gave: null,
    passport_address: null,
    birthday: null,
    accept_privacy: false,
    signed_privacy: false,
    accept_rules: false,
    signed_rules: false,

    sms_code: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

export default {
    getStateValue: (state) => (key) => {
        let result = undefined;

        if (state.hasOwnProperty(key)) {
            result = state[key];
        }

        return result;
    },
}
